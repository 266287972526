<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <a class="filtro" color="#000" dark v-on="on">
        <v-icon left color="primary">mdi-filter-variant</v-icon>
        <span style="color: #000">{{ $t("filter") }}</span>
      </a>
    </template>

    <v-card class="px-3 py-3">
      <!-- <pre>
        {{Skills}}
      </pre> -->
      <v-container fluid>
        <v-card flat max-width="550">
          <v-row>
            <v-col cols="12" md="12" v-if="!database">
              <v-autocomplete
                v-model="vacancy"
                :items="getVacancies"
                item-text="title"
                item-value="id"
                outlined
                :label="$t('vacancy')"
                dense
                hide-details
                :search-input.sync="searchV"
                no-filter
                @change="$emit('change', vacancy)"
              ></v-autocomplete>
            </v-col>
            <v-col class="12" md="12">
              <template v-for="(formation, index) in filter.formation">
                <v-row :key="`${index}formation`">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="formation.course"
                      :items="getFormation"
                      item-text="text"
                      item-value="key"
                      :label="$t('academicEdu')"
                      outlined
                      dense
                      hide-details
                      @change="apply()"
                      :search-input.sync="searchF"
                      no-filter
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-autocomplete
                      v-model="formation.level"
                      :items="academicLevel"
                      item-text="text"
                      item-value="key"
                      :label="$t('form_candidate_academic_level')"
                      outlined
                      dense
                      @change="apply()"
                      hide-details
                      :search-input.sync="searchFL"
                      no-filter
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1" class="text-center mt-1">
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="
                        index === 0 ? addFormation() : removeFormation(index)
                      "
                    >
                      <!-- <v-icon small>mdi-plus</v-icon> -->
                      <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                      <v-icon v-else small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="filter.areasInterest"
                :items="getAreas"
                item-text="text"
                item-value="key"
                outlined
                :label="$t('form_candidate_inter')"
                dense
                multiple
                chips
                hide-details
                @change="changedField"
                :search-input.sync="searchA"
                no-filter
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="filter.province"
                :items="getProvince"
                item-text="text"
                item-value="key"
                :label="$t('province')"
                outlined
                dense
                hide-details
                :search-input.sync="searchP"
                no-filter
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="filter.professionalExperience"
                :label="$t('professionalExperience')"
                type="number"
                outlined
                dense
                hide-details
                @input="changedFieldSingle('professionalExperience')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="filter.otherSkills"
                :label="$t('form_candidate_tools')"
                :items="getSkill"
                multiple
                outlined
                dense
                chips
                hide-details
                @input="changedField"
                :search-input.sync="searchS"
                no-filter
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <template v-for="(lang, index) in filter.languages">
                <v-row :key="`${index}languages`">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="lang.name"
                      :items="languages"
                      :item-text="$t('text')"
                      item-value="key"
                      outlined
                      :label="$t('language')"
                      dense
                      chips
                      hide-details
                      @change="changedField"
                      :search-input.sync="searchL"
                      no-filter
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-autocomplete
                      v-model="lang.level"
                      :items="levelLanguages"
                      :item-text="$t('text')"
                      item-value="key"
                      :label="$t('level')"
                      outlined
                      dense
                      hide-details
                      @change="changedField"
                      :search-input.sync="searchLL"
                      no-filter
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1" class="text-center mt-1">
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="
                        index === 0 ? addLanguage() : removeLanguage(index)
                      "
                    >
                      <!-- <v-icon small>mdi-plus</v-icon> -->
                      <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                      <v-icon v-else small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="filter.minYear"
                :label="$t('minimal_age')"
                type="number"
                outlined
                dense
                hide-details
                @input="changedFieldSingle('minYear')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="filter.maxYear"
                :label="$t('maximum_age')"
                type="number"
                outlined
                dense
                hide-details
                @input="changedFieldSingle('maxYear')"
              ></v-text-field>
            </v-col>
            <v-col v-if="database" cols="12" md="6">
              <v-currency-field
                v-model.number="filter.minSalary"
                :label="$t('minimal_wage')"
                outlined
                dense
                @input="changedFieldSingle('minSalary')"
              />
            </v-col>
            <v-col v-if="database" cols="12" md="6">
              <v-currency-field
                v-model.number="filter.maxSalary"
                :label="$t('maximum_wage')"
                outlined
                dense
                @input="changedFieldSingle('maxSalary')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn text class="mx-2" @click="clean()" color="primary">{{
              $t("clean")
            }}</v-btn>
            <v-btn color="primary" @click="apply()">{{ $t("search") }}</v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { CANDIDATE_SKILL } from "../graphql/Query.gql";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "CandidateFilter",
  mixins: [replaceSpecialCharsMixins],
  props: {
    database: Boolean,
    vacancies: Array,
    vacancyId: String,
  },
  data: () => ({
    Skills: [],
    formations: [
      {
        course: undefined,
        level: undefined,
      },
    ],
    filter: {
      areasInterest: undefined,
      province: undefined,
      otherSkills: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      professionalExperience: undefined,
      vacancy: undefined,
    },
    searchV: "",
    searchA: "",
    searchF: "",
    searchP: "",
    searchFL: "",
    searchLL: "",
    searchL: "",
    searchS: "",
  }),
  apollo: {
    Skills: {
      query: CANDIDATE_SKILL,
      fetchPolicy: "no-cache",
    },
  },
  created() {
    this.vacancy = this.vacancyId;
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
    }
  },
  computed: {
    ...mapGetters({
      trainingAreas: "library/trainingAreas",
      provinces: "library/provinces",
    }),
    languages() {
      let resultItems = [
        { key: "portuguese", text: this.$t("portuguese") },
        { key: "english", text: this.$t("english") },
        { key: "french", text: this.$t("french") },
        { key: "german", text: this.$t("german") },
        { key: "spanish", text: this.$t("spanish") },
      ];
      if (this.searchL) {
        const text = this.replaceSpecialChars(this.searchL);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },
    levelLanguages() {
      let resultItems = [
        { key: "basic", text: this.$t("basic") },
        { key: "intermediate", text: this.$t("intermediate") },
        { key: "advanced", text: this.$t("advanced") },
      ];

      if (this.searchLL) {
        const text = this.replaceSpecialChars(this.searchLL);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },
    academicLevel() {
      let resultItems = [
        { key: "basic", text: this.$t("basic") },
        { key: "elementary", text: this.$t("elementary") },
        { key: "dedium", text: this.$t("dedium") },
        { key: "bachelor", text: this.$t("bachelor") },
        { key: "graduation", text: this.$t("graduation") },
        { key: "master_degree", text: this.$t("master_degree") },
        { key: "doctorate", text: this.$t("doctorate") },
        { key: "Phd", text: this.$t("Phd") },
      ];
      if (this.searchFL) {
        const text = this.replaceSpecialChars(this.searchFL);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },

    getVacancies() {
      let resultItems = [...this.vacancies];
      if (this.searchV) {
        const text = this.replaceSpecialChars(this.searchV);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
        if (resultItems.length === 1) {
          this.vacancies.forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getAreas() {
      let resultItems = [...this.trainingAreas(this.$i18n.locale)];
      if (this.searchA) {
        const text = this.replaceSpecialChars(this.searchA);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
         if (resultItems.length === 1) {
          this.trainingAreas(this.$i18n.locale).forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getFormation() {
      let resultItems = [...this.trainingAreas(this.$i18n.locale)];
      if (this.searchF) {
        const text = this.replaceSpecialChars(this.searchF);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
          if (resultItems.length === 1) {
          this.trainingAreas(this.$i18n.locale).forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getProvince() {
      let resultItems = [...this.provinces(this.$i18n.locale)];
      if (this.searchP) {
        const text = this.replaceSpecialChars(this.searchP);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
         if (resultItems.length === 1) {
          this.provinces(this.$i18n.locale).forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getSkill() {
      let resultItems = [...this.Skills];
      if (this.searchS) {
        const text = this.replaceSpecialChars(this.searchS);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t).includes(text)
        );
      }
      return resultItems;
    },
  },
  methods: {
    addFormation() {
      this.filter.formation.push({ course: undefined, level: undefined });
    },
    addLanguage() {
      this.filter.languages.push({ name: undefined, level: undefined });
    },
    apply() {
      // if (this.filter.formation[0].course || this.filter.formation[0].level) {
      this.$emit("apply", this.filter);
      // }
    },
    changedField(e) {
      if (!e) {
        this.apply();
      } else if (e.length === 0) {
        this.apply();
      }
    },
    changedFieldSingle(field) {
      console.log(field);
      if (!this.filter[field]) {
        this.filter[field] = undefined;
        this.apply();
      }
    },
    clean() {
      this.filter = {
        areasInterest: undefined,
        otherSkills: undefined,
        province: undefined,
        formation: [
          {
            course: undefined,
            level: undefined,
          },
        ],
        languages: [
          {
            name: undefined,
            level: undefined,
          },
        ],
        maxYear: undefined,
        minYear: undefined,
        maxSalary: undefined,
        minSalary: undefined,
        professionalExperience: undefined,
      };
      this.$emit("clean", this.filter);
    },
    removeFormation(index) {
      this.filter.formation.splice(index, 1);
    },
    removeLanguage(index) {
      this.filter.languages.splice(index, 1);
    },
  },
};
</script>
