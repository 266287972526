<template>
  <div class="fill-heigt pt-0" fluid>
    <v-row>
      <v-col cols="12" md="4">
        <div class="text-left black--text" style="margin-top: 3%">
          {{ $t("create_database") }}
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <!-- <v-btn
          icon
          color="primary"
          @click="infoDialog = true"
        >
          <v-icon>info</v-icon>
        </v-btn> -->
          <template>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="mx-2">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <div v-html="$t('tooltip_candidate_filter')"></div>
            </v-tooltip>
          </template>
          <candidate-filter
            :database="true"
            @apply="applyCandidateFilter"
            @clean="applyCandidateFilter"
          />
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchCandidate')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col cols="12" md="7">
        <v-row class="d-flex flex-row align-center ml-2">
          <v-checkbox
            v-model="selectAll"
            color="primary"
            @change="checkSelectAll"
          >
            <template v-slot:label class="pt-50">
              <div>{{ $t("select") }}</div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="filter.belongsToMany"
            color="primary"
            class="mx-2"
            :value="currentUser.entity.id"
            @change="applyFilter()"
          >
            <template v-slot:label class="pt-50">
              <div>{{ $t("MyCandidates") }}</div>
            </template>
          </v-checkbox>
          <div style="width: 35%">
            <v-autocomplete
              v-model="filter.favorites"
              :items="lists"
              item-text="name"
              item-value="candidates"
              rounded
              dense
              clearable
              :label="$t('favorites')"
              class="mb-2"
              hide-details
              @change="applyFilter()"
            ></v-autocomplete>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="text-right">
        <v-btn color="primary" @click="addDialog = true">
          <v-icon left>mdi-account-multiple-plus</v-icon
          >{{ $t("create_candidate") }}
        </v-btn>

        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="accessLevel(currentUser, 'database_send_vacancy')"
                :disabled="!isSelected"
                text
                @click="openSelectVacancyDialog()"
                style="margin-left: 2%"
                color="primary"
              >
                <v-icon left color="primary">mdi-send</v-icon
                >{{ $t("sendvacancy") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_sendVacancy')"></div>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>

    <v-row class="px-0">
      <template v-for="i in 12">
        <v-col v-if="loadingData" cols="12" md="3" xl="3" :key="i">
          <skeleton-loading />
        </v-col>
      </template>

      <template v-if="!loadingData">
        <v-col
          v-for="candidate in filteredItems"
          cols="12"
          md="3"
          xl="3"
          :key="candidate.id"
        >
          <candidate-card
            :candidate="candidate"
            :filter="filter"
            :from="'candidates'"
            :selected="selectedCandidates"
            :selectAll="selectAll"
            @checked="checkedCandidate"
            :match="'0,00'"
          />
        </v-col>
      </template>
    </v-row>

    <v-row justify="center" v-if="filteredItems.length < 1">
      <div class="d-flex justify-center">
        <v-col cols="12">
          <div class="grey--text" v-html="$t('tooltip_db')"></div>
        </v-col>
      </div>
    </v-row>

    <add-candidate-dialog :dialog="addDialog" @close="addDialog = false" />
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <select-vacancy-dialog
      :candidates="selectedCandidates"
      :dialog="selectVacancyDialog"
      @close="selectVacancyDialog = false"
    />

    <div id="sensor"></div>
  </div>
</template>

<script>
import {
  GET_CANDIDATES_ENTITY_QUERY,
  GET_CANDIDATE_LISTS_QUERY,
} from "./../graphql/Query";
import { UPDATED_CANDIDATE } from "./../graphql/subscription";
import { mapActions, mapGetters } from "vuex";
import scrollMonitor from "scrollmonitor";
import CandidateCard from "../../../components/CandidateCard.vue";
import CandidateFilter from "../../../components/CandidateFilter.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
// import Pagination from '../../../components/Pagination.vue'
import AddCandidateDialog from "../components/AddCandidateDialog.vue";
import SelectVacancyDialog from "./../components/SelectVacancyDialog";
import SkeletonLoading from "../../../components/SkeletonLoading.vue";
// import InfiniteScroll from '../../../components/InfiniteScroll.vue'
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "Candidate",
  components: {
    CandidateCard,
    CandidateFilter,
    InfoViewDialog,
    // Pagination,
    AddCandidateDialog,
    SelectVacancyDialog,
    SkeletonLoading,
    // InfiniteScroll
  },
  mixins: [replaceSpecialCharsMixins, accessLevelMixins],
  data: () => ({
    addDialog: false,
    appliedFilter: true,
    candidates: [],
    filter: {
      areasInterest: undefined,
      otherSkills: undefined,
      province: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 50,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    filterSlot: undefined,
    infoDialog: false,
    loadingData: true,
    list: undefined,
    lists: [],
    // totalPages: 1,
    selectAll: false,
    selectedCandidates: [],
    selectVacancyDialog: false,
    textSearch: undefined,
  }),
  apollo: {
    candidates: {
      query: GET_CANDIDATES_ENTITY_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return { filterCandidate: this.filter };
      },
      result({ loading }) {
        if (!loading) {
          this.loadingData = false;
        }
      },
    },
    lists: {
      query: GET_CANDIDATE_LISTS_QUERY,
      fetchPolicy: "no-cache",
    },
    $subscribe: {
      message: {
        query: UPDATED_CANDIDATE,
        result({ data }) {
          
          if (data.updateCandidate) {
            const index = this.candidates.candidates.findIndex(
              (i) => i.id === data.updateCandidate.id
            );
            if (index === -1) {
              console.log('subs',index,data);
              this.candidates.candidates.unshift(data.updateCandidate);
            }
          }
        },
      },
    },
  },
  created() {
    if (
      this.$route.query.filter &&
      this.verifyFilter(this.$route.query.filter)
    ) {
      let urlFilter = this.$route.query.filter;
      urlFilter.limit = 50;
      this.filter = urlFilter;
      this.applyFilter();
    }

    // eslint-disable-next-line no-undef
    Fire.$on("addedCandidates", (candidates) => {
      this.candidates.candidates = [
        ...candidates,
        ...this.candidates.candidates,
      ];
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getCandidates: "candidate/getCandidates",
    }),
    filteredItems() {
      let resultItems = this.candidates.candidates
        ? this.candidates.candidates
        : [];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter(
          (t) =>
            this.replaceSpecialChars(t.name + " " + t.lastName).includes(
              text
            ) ||
            (t.profession &&
              this.replaceSpecialChars(t.profession).includes(text))
        );
      }
      return resultItems;
    },
    isSelected() {
      return this.selectedCandidates.length > 0;
    },
    infoView() {
      return {
        icon: "mdi-badge-account-horizontal-outline",
        title: this.$t("create_database"),
        content: this.$t("info_database"),
      };
    },
  },
  methods: {
    ...mapActions({
      setCandidatesState: "candidate/setCandidates",
      setCandidatesScrolled: "candidate/setCandidatesScrolled",
    }),
    applyCandidateFilter(filter) {
      this.filter = {
        ...this.filter,
        areasInterest: filter.areasInterest,
        otherSkills: filter.otherSkills,
        province: filter.province ? `${filter.province}` : filter.province,
        formation: filter.formation,
        languages: filter.languages,
        maxYear: filter.maxYear,
        minYear: filter.minYear,
        maxSalary: filter.maxSalary,
        minSalary: filter.minSalary,
        professionalExperience: filter.professionalExperience,
      };
      this.applyFilter();
    },
    async applyFilter() {
      this.loadingData = true;
      this.filter.page = 1;
      await this.$apollo.queries.candidates.refetch();
    },
    checkedCandidate(id) {
      const index = this.selectedCandidates.findIndex((c) => c.id == id);
      if (index > -1) {
        this.selectedCandidates.splice(index, 1);
      } else {
        const candidate = this.filteredItems.find((c) => c.id == id);
        this.selectedCandidates.push(Object.assign({}, { ...candidate }));
      }
    },
    checkSelectAll() {
      if (this.selectAll) {
        for (let index = 0; index < this.filteredItems.length; index++) {
          this.selectedCandidates.push(
            Object.assign({}, this.filteredItems[index])
          );
        }
      } else {
        this.selectedCandidates = [];
      }
    },
    fetchMore() {
      this.filter.page += 1;
      console.log("this.filter.page: ", this.filter.page);
      this.$apollo.queries.candidates.fetchMore({
        variables: {
          filter: this.filter,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.candidates.candidates = [
            ...this.candidates.candidates,
            ...fetchMoreResult.candidates.candidates,
          ];
          return {
            candidates: {
              candidates: [...this.candidates.candidates],
              totalDocs: fetchMoreResult.candidates.totalDocs,
              totalPages: fetchMoreResult.candidates.totalPages,
            },
          };
        },
      });
    },
    openSelectVacancyDialog() {
      if (this.selectedCandidates.length > 0) {
        this.selectVacancyDialog = true;
      }
    },
    verifyFilter(filter) {
      let count = 0;
      for (const key in filter) {
        switch (key) {
          case "formation":
            if (filter[key][0].course) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;

          case "languages":
            if (filter[key][0].name) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;

          case "limit":
            break;

          case "page":
            break;

          default:
            if (filter[key]) {
              count++;
            }
            break;
        }
      }

      return count > 0;
    },
  },
  mounted() {
    const el = document.getElementById("sensor");
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (this.candidates && this.filter.page <= this.candidates.totalPages) {
        this.fetchMore();
      }
    });
  },
};
</script>