export default class Entity {
  constructor (
    id, available, candidates, client, description, finalDate, formation, languages, locale, category, place,
    privat, professionalExperience, publicationDate, status, title, type, views
  ) {
    this.id = id
    this.available = available
    this.candidates = candidates
    this.client = client
    this.description = description
    this.finalDate = finalDate
    this.formation = formation
    this.languages = languages
    this.locale = locale
    this.category = category
    this.place = place
    this.private = privat
    this.professionalExperience = professionalExperience
    this.publicationDate = publicationDate
    this.status = status
    this.title = title
    this.type = type // Emprego || Estágio
    this.views = views
  }
}