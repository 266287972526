import gql from 'graphql-tag'
import clientFragment from './../../client/graphql/ClientFrangment'

export default gql`{
  id
  available
  client {
    ...${clientFragment}
  }
  description
  finalDate
  formation {
    course
    level
    status
    institution
    year
  }
  languages {
    name
    level
  }
  locale
  category
  place
  private
  professionalExperience {
    description
    yearsExperience
  }
  publicationDate
  status
  title
  type
}`