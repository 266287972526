import gql from 'graphql-tag'
import candidateFragment from './../../candidate/graphql/CandidateFragment'
import clientFragment from './../../client/graphql/ClientFrangment'

export default gql `{
  id
  available
  candidacies {
    id
    appliedFor
    candidate {
      ...${candidateFragment}
    }
    notes {
      name
      text
      time
    }
    tests {
      id
      candidate
      date
      description
      image
      questions {
        question
        objective
        implication
        alternatives {
          answer
          response
          value
        }
        answer
        totalCorrect
        percentage
        picture
      }
      result
      status
      time
      title
      type
      # user {
      #   id
      #   name
      #   office
      #   photo
      # }
      vacancy {
        id
      }
    }
    favorite
    status
  }
  client {
    ...${clientFragment}
  }
  description
  entity {
    id
    logo
    name
    about
    media{
      facebook
      linkedin
      twitter
    }
  }
  finalDate
  formation {
    course
    level
    status
    institution
    year
    weight
  }
  languages {
    name
    level
    weight
  }
  areaInterest
  weight
  locale
  category
  place
  private
  professionalExperience {
    description
    yearsExperience
    weight
  }
  publicationDate
  status
  title
  type
  views
}`