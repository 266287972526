export default {
  methods: {
    days (professionalExperience) {
      let sum = 0;

      if (professionalExperience) {
        professionalExperience.map((exp) => {
          if (exp.startDate) {
            sum += this.sumeExp(exp);
          }
        });
        return Math.round(sum / 365);
      }
      return sum;
    },
    sumeExp ({ startDate, endDate }) {
      const dateIni = new Date(startDate);
      const dateFinal = endDate !== null ? new Date(endDate) : new Date();
      const differenceInTime = dateFinal.getTime() - dateIni.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays;
    }
  }
}