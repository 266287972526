import gql from 'graphql-tag'

export default gql`{
  id
  email
  entity {
    id
    address
    areasExpertise
    email
    license
    modules {
      module {
        id
        name
        permissions {
          id
          name
        }
      }
      status
    }
    name
    nuit
    telephone
  }
  name
  office
  photo
  type
  telephone
  role {
    id
    name
    permissions {
      id
      name
    }
  }
}
`