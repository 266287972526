import gql from 'graphql-tag'
import vacancyFragment from './VacancyFragment'

export const GET_VACANCIES_ENTITY_QUERY = gql`
  query GetVacanciesEntity($private: Boolean) {
    vacancies: getVacanciesEntity(private: $private) {
      ...${vacancyFragment}
    }
  }
`

export const GET_NOTES_VACANCY_CANDIDATE_QUERY = gql`
  query getNotesVacancyCandidate($vacancyId: ID!, $candidateId: ID!) {
    notes: getNotesVacancyCandidate(vacancyId: $vacancyId, candidateId: $candidateId) {
      name
      text
      time
      ranking
    }
  }
`

export const GET_PUBLISHED_VACANCIES_QUERY = gql`
  query GetPublishedVacancies($ranking: Boolean) {
    vacancies: getPublishedVacancies(ranking: $ranking) {
      id
      title
      candidacies {
        appliedFor
        candidate
      }
      favorites {
        id
        candidates
        name
      }
      finalDate
      publicationDate
      weight
      areaInterest
      professionalExperience{
        description
        yearsExperience
        weight
      }
      formation{
        course
        level
        weight
      }
      languages{
        name
        level
        weight
      }
    }
  }
`

export const GET_VACANCY_LIST_QUERY = gql`
  query GetVacancyList($id: ID!) {
    lists: getVacancyList(id: $id) {
      id
      candidates
      name
    }
  }
`

export const GET_USERS_ENTITY_QUERY = gql`
  query GetUsersEntity {
    users: getUsersEntity {
      id
      email
      name
      office
      photo
    }
  }
`

export const GET_VACANCY_BY_ID_QUERY = gql`
  query GetVacancyById($id: ID!) {
    vacancy: getVacancyById(id: $id) {
      ...${vacancyFragment}
    }
  }
`

export const GET_VACANCY_BY_ID_GUEST_QUERY = gql`
  query GetVacancyByIdGuest($id: ID!) {
    vacancy: getVacancyByIdGuest(id: $id) {
      id
      available
      client {
        id
        name
      }
      description
      entity {
        id
        logo
        name
      }
      finalDate
      formation {
        course
        level
        status
        institution
        year
      }
      languages {
        name
        level
      }
      locale
      category
      place
      private
      professionalExperience {
        description
        yearsExperience
      }
      publicationDate
      status
      title
      type
      views
    }
  }
`