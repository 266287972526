import gql from "graphql-tag";

export default gql`
  {
    id
    address
    candidates {
      interviewee {
        id
      }
    }
    entity {
      id
    }
  }
`;
