import gql from "graphql-tag";
import interviewFragment from "./InterviewFragment";
import vacancyFragment from "./VacancyFragment";
import vacancy2Fragment from "./Vacancy2Fragment";

export const ADD_CANDIDATE_TO_VACANCY_LIST_MUTATION = gql`
  mutation AddCandidateToVacancyList(
    $id: ID!
    $listId: ID!
    $candidateId: ID!
  ) {
    addCandidateToVacancyList(
      id: $id
      listId: $listId
      candidateId: $candidateId
    ) {
      id
      candidates
      name
    }
  }
`;

export const ADD_NOTE_CANDIDATE_MUTATION = gql`
  mutation AddNoteCandidate($id: ID!, $text: String!) {
    addNoteCandidate(id: $id, text: $text) {
      id
      photo
      name
      text
      time
      user
    }
  }
`;

export const ADD_NOTE_VACANCY_MUTATION = gql`
  mutation AddNoteVacancy(
    $vacancyId: ID!
    $candidateId: ID!
    $note: String!
    $ranking: Boolean
    $time: String
  ) {
    addNoteVacancy(
      vacancyId: $vacancyId
      candidateId: $candidateId
      note: $note
      ranking: $ranking
      time: $time
    ) {
      name
      text
      time
      ranking
    }
  }
`;
export const DELETE_NOTE_VACANCY_MUTATION = gql`
  mutation deleteNote($vacancyId: ID!, $candidateId: ID!, $time: String) {
    deleteNote(vacancyId: $vacancyId, candidateId: $candidateId, time: $time) {
      time
    }
  }
`;

export const ADD_VIEWS_VACANCY = gql`
  mutation AddViewsVacancy($id: ID!) {
    addViewsVacancy(id: $id)
  }
`;

export const CREATE_VACANCY_MUTATION = gql`
  mutation CreateVacancy($vacancyInput: VacancyInput) {
    createVacancy(vacancyInput: $vacancyInput) {
      ...${vacancy2Fragment}
    }
  }
`;

export const CREATE_VACANCY_LIST_MUTATION = gql`
  mutation CreateVacancyList($id: ID!, $candidateId: ID!, $name: String!) {
    createVacancyList(id: $id, candidateId: $candidateId, name: $name) {
      id
      candidates
      name
    }
  }
`;

export const DELETE_VACANCY_MUTATION = gql`
  mutation DeleteVacancy($id: ID!) {
    deleteVacancy(id: $id) {
      id
    }
  }
`;

export const DELETE_VACANCY_LIST_MUTATION = gql`
  mutation DeleteVacancyList($id: ID!, $listId: ID!) {
    deleteVacancyList(id: $id, listId: $listId)
  }
`;

export const REMOVE_CANDIDATE_FROM_VACANCY_LIST_MUTATION = gql`
  mutation RemoveCandidateFromVacancyList(
    $id: ID!
    $listId: ID!
    $candidateId: ID!
  ) {
    removeCandidateFromVacancyList(
      id: $id
      listId: $listId
      candidateId: $candidateId
    ) {
      id
      candidates
      name
    }
  }
`;

export const SEND_TEST_MUTATION = gql`
  mutation SendTests(
    $candidates: [CandidateInput]
    $date: String
    $tests: [TestInput]
    $time: String
    $vacancyId: ID
  ) {
    sendTests(
      candidates: $candidates
      date: $date
      tests: $tests
      time: $time
      vacancyId: $vacancyId
    ) {
      candidacies {
        id
        tests {
          date
          description
          image
          questions {
            question
            objective
            implication
            alternatives {
              answer
              response
              value
            }
            totalCorrect
            percentage
            picture
          }
          result
          status
          time
          title
          type
        }
      }
      error
    }
  }
`;

export const SCHEDULE_INTERVIEW_MUTATION = gql`
  mutation ScheduleInterview($interviewInput: InterviewInput) {
    scheduleInterview(interviewInput: $interviewInput) {
      ...${interviewFragment}
    }
  }
`;

export const UPDATE_VACANCY_MUTATION = gql`
  mutation UpdateVacancy($id: ID!, $vacancyInput: VacancyInput) {
    updateVacancy(id: $id, vacancyInput: $vacancyInput) {
      ...${vacancyFragment}
    }
  }
`;

export const UPDATE_CANDIDATE_RATING_MUTATION = gql`
  mutation UpdateCandidateRating($id: ID!, $rating: Float!) {
    updateCandidateRating(id: $id, rating: $rating)
  }
`;
export const ADD_VIEW = gql`
  mutation addView($candidateId: ID, $entity: ID) {
    addView(candidateId: $candidateId, entity: $entity) {
      views
      candidateID
    }
  }
`;
